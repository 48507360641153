import React from 'react';

const renderBasedOnType = (type, content) => {
    if (type === 'url') {
        return <a href={content} className="aside__link" target="_blank" rel="noopener noreferrer">{content}</a>;
    } else if (type === 'mail') {
        return <a href={'mailto:' + content} className="aside__link">{content}</a>;
    } else {
        return <span className="aside__value">{content}</span>;
    }
};

const AsideContent = (props) => (
    <div className="aside__content">
        <h2 className="aside__title">{props.title}</h2>

        <div className="aside__table">
            {props.blocks.map((data, index) => {
                return <div key={`aside_item_${index}`} className="aside__table-row">
                    {data.key !== '' &&
                        <span className="aside__key">{data.key}</span>
                    }
                    {renderBasedOnType(data.type, data.value)}
                </div>
            })}
        </div>
    </div>
);

export default AsideContent;