import React from 'react';

import {enableBodyScroll, disableBodyScroll} from 'body-scroll-lock';

class Aside extends React.Component {
    constructor(props) {
        super(props);
        this.name = props.name;
        this.occupation = props.occupation;
        this.children = props.children;

        this.onNavCheckboxChange = this.onNavCheckboxChange.bind(this);

        this.$aside = React.createRef();
        this.$navCheckbox = React.createRef();
    };

    onNavCheckboxChange() {
        const isCheck = this.$navCheckbox.current.checked;
        console.log('Aside -> onNavCheckboxChange -> isCheck', isCheck);
        console.log(this.$navCheckbox);
        if (isCheck) {
            disableBodyScroll(this.$aside.current);
        } else {
            enableBodyScroll(this.$aside.current);
        }
    }

    render() {
        return (
            <>
                <input type="checkbox" id="navigation-input"
                    ref={this.$navCheckbox}
                    onChange={this.onNavCheckboxChange} />
                <label htmlFor="navigation-input" className="navigation-label" aria-label="Navigation Toggle">
                    <span className="navigation-label__lines"></span>
                </label>
                <aside className="aside" ref={this.$aside}>
                    <div className="aside__content-wrapper">
                        <div className="intro-print">
                            <span className="intro-print__name">{this.name}</span>
                            <span className="intro-print__occupation">{this.occupation}</span>
                        </div>
                        {this.children}
                    </div>
                </aside>
            </>
        )
    };
};

export default Aside;