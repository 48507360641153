import React from 'react';

const MainContent = (props) => (
    <>
        <h2 className="main__headline">{props.headline}</h2>

        <div className="main__blocks">

            {props.blocks.map((data, index) => {
                return <div className="main__block" key={`main_block_${index}`}>
                    <span className="main__date">{data.date}</span>
                    <span className="main__job">{data.job}</span>
                    <span className="main__company">{data.company}</span>
                    <ul className="main__list">
                        {data.list.map((item, index) => {
                            return <li key={`list_item_${index}`}
                                className="main__item">{item.item}</li>
                        })}
                    </ul>
                </div>
            })}
        </div>
    </>
);

export default MainContent;